import { defineStyleConfig } from '@chakra-ui/react'

// define the base component styles
const baseStyle = {
  maxW: '1480px'
  // padding: 0
}

// export the component theme
const containerTheme = defineStyleConfig({ baseStyle })

export default containerTheme
