import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle({
  root: {
    borderBottom: '#E5E4D7'
  },
  tab: {
    bg: 'green',
    _selected: {
      color: '#DE4800'
    }
  }
})

export const tabsTheme = defineMultiStyleConfig({ baseStyle })
