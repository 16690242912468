import { IProductItem } from '@common/interface/product'
import {
  getCouponFromCart,
  mapItemCartToItemGa4,
  mapItemProductToItemGa4,
  mapToPromotionGA4
} from '@lib/util/map-ga4'
import { Cart, Discount, LineItem, Order } from '@medusajs/medusa'
import { useState } from 'react'
import { Promotions } from 'types/global'

interface UseGA4DataLayerReturn {
  viewListItemTag: (items: any) => void
  selectItemTag: (items: any) => void
  viewItemTag: (currency: string, value: number, items: any) => void
  addToCartTag: (currency: string, value: number, items: any) => void
  removeFromCartTag: (currency: string, value: number, items: any) => void
  viewCartTag: (currency: string, value: number, items: any) => void
  viewPromotionTag: (
    items: any,
    promotions?: Promotions[],
    discounts?: Discount[]
  ) => void
  selectPromotionTag: (
    items: any,
    promotions?: Promotions[],
    discounts?: Discount[]
  ) => void
  beginCheckoutTag: (cart: Cart) => void
  addShippingInfoTag: (cart: Cart) => void
  addPaymentInfoTag: (cart: Cart) => void
  purchaseTag: (cart: Cart, transaction_id: string) => void
  refundTag: (order: Order, dl?: any) => void
}

const useGA4DataLayer = (): UseGA4DataLayerReturn => {
  const [isBeginCheckout, setIsBeginCheckout] = useState(false)
  const [isViewItem, setIsViewItem] = useState(false)

  let dataLayer: any = null

  // Check if window is defined for SSR and if environment is production
  const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === 'production'
  dataLayer =
    typeof window !== 'undefined' && isProduction
      ? (window as any).dataLayer
      : null

  const viewListItemTag = (items: IProductItem[]) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemProductToItemGa4(items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          items: itemsGa4
        }
      })
    }
  }

  const selectItemTag = (items: IProductItem[]) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemProductToItemGa4(items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: itemsGa4
        }
      })
    }
  }

  const viewItemTag = (
    currency: string,
    value: number,
    items: IProductItem[]
  ) => {
    if (dataLayer != null && !isViewItem) {
      const itemsGa4 = mapItemProductToItemGa4(items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency,
          value,
          items: itemsGa4
        }
      })
      setIsViewItem(true)
    }
  }

  const addToCartTag = (currency: string, value: number, items: LineItem[]) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemCartToItemGa4(items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency,
          value,
          items: itemsGa4
        }
      })
    }
  }

  const removeFromCartTag = (
    currency: string,
    value: number,
    items: LineItem[]
  ) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemCartToItemGa4(items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          currency,
          value,
          items: itemsGa4
        }
      })
    }
  }

  const viewCartTag = (currency: string, value: number, items: LineItem[]) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemCartToItemGa4(items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          currency,
          value,
          items: itemsGa4
        }
      })
    }
  }

  const viewPromotionTag = (
    items: LineItem[],
    promotions?: Promotions[],
    discounts?: Discount[]
  ) => {
    if (dataLayer != null) {
      const promortionsGa4Datalayer = mapToPromotionGA4(
        items,
        promotions,
        discounts
      ).map((p) => ({
        event: 'view_promotion',
        ecommerce: p
      }))

      dataLayer.push({ ecommerce: null })

      for (let i = 0; i < promortionsGa4Datalayer.length; i++) {
        dataLayer.push(promortionsGa4Datalayer[i])
      }
    }
  }

  const selectPromotionTag = (
    items: LineItem[],
    promotions?: Promotions[],
    discounts?: Discount[]
  ) => {
    if (dataLayer != null) {
      const promortionsGa4Datalayer = mapToPromotionGA4(
        items,
        promotions,
        discounts
      ).map((p) => ({
        event: 'select_promotion',
        ecommerce: p
      }))

      dataLayer.push({ ecommerce: null })

      for (let i = 0; i < promortionsGa4Datalayer.length; i++) {
        dataLayer.push(promortionsGa4Datalayer[i])
      }
    }
  }

  const beginCheckoutTag = (cart: Cart) => {
    if (dataLayer != null && !isBeginCheckout) {
      const itemsGa4 = mapItemCartToItemGa4(cart.items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: cart.region?.currency_code ?? 'sgd',
          value: (cart.subtotal ?? 0) / 100,
          coupon: getCouponFromCart(cart),
          items: itemsGa4
        }
      })
      setIsBeginCheckout(true)
    }
  }

  const addShippingInfoTag = (cart: Cart) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemCartToItemGa4(cart.items)
      const shipping_tier = cart.shipping_methods[0].shipping_option.name
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
          currency: cart.region?.currency_code ?? 'sgd',
          value: (cart.subtotal ?? 0) / 100,
          coupon: getCouponFromCart(cart),
          shipping_tier,
          items: itemsGa4
        }
      })
    }
  }

  const addPaymentInfoTag = (cart: Cart) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemCartToItemGa4(cart.items)
      const payment_type = cart.payment_session?.provider_id ?? 'stripe'
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          currency: cart.region?.currency_code ?? 'sgd',
          value: (cart.subtotal ?? 0) / 100,
          coupon: getCouponFromCart(cart),
          payment_type,
          items: itemsGa4
        }
      })
    }
  }

  const purchaseTag = (cart: Cart, transaction_id: string) => {
    if (dataLayer != null) {
      const itemsGa4 = mapItemCartToItemGa4(cart.items)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: cart.region?.currency_code ?? 'sgd',
          value: (cart.subtotal ?? 0) / 100,
          coupon: getCouponFromCart(cart),
          shipping: (cart.shipping_total ?? 0) / 100,
          tax: (cart.tax_total ?? 0) / 100,
          transaction_id,
          items: itemsGa4
        }
      })
    }
  }

  const refundTag = (order: Order, dl?: any) => {
    if (dl != null) {
      const itemsGa4 = mapItemCartToItemGa4(order.cart.items)
      dl.push({ ecommerce: null })
      dl.push({
        event: 'refund',
        ecommerce: {
          currency: order.region?.currency_code ?? 'sgd',
          value: (order.subtotal ?? 0) / 100,
          coupon: getCouponFromCart(order.cart),
          shipping: (order.shipping_total ?? 0) / 100,
          tax: (order.tax_total ?? 0) / 100,
          transaction_id: order?.payments[0]?.id ?? '',
          items: itemsGa4
        }
      })
    }
  }

  return {
    viewListItemTag,
    selectItemTag,
    viewItemTag,
    addToCartTag,
    viewCartTag,
    removeFromCartTag,
    viewPromotionTag,
    selectPromotionTag,
    beginCheckoutTag,
    addShippingInfoTag,
    addPaymentInfoTag,
    purchaseTag,
    refundTag
  }
}

export default useGA4DataLayer
