import { m } from 'framer-motion'

const textStyles = {
  // All fonts-size, line-height:
  // 10 12
  // 11 14
  // 14 20
  // 16 24 //
  // 17 20 //
  // 18 21 //
  // 18 24 //
  // 19 24 //
  // 20 24 //
  // 20 26 //
  // 22 26 //
  // 22 28 //
  // 22 29 //
  // 24 31 //
  // 24 38 //
  // 27 35
  // 28 33
  // 28 36
  // 32 38
  // 34 40
  // 34 44
  // 36 43 //
  // 36 48 //
  // 36 46 //
  // 38 48 //
  // 40 52 //
  // 42 50 //
  // 50 60
  // 56 62 //
  // 74 89
  // 128 154 -> 110 120

  // base, sm, md
  // ['18px', '20px', '22px']
  // Checked lists
  body: {
    fontSize: ['15px', '16px', '16px'],
    fontWeight: 400,
    lineHeight: ['17px', '20px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  linkItem: {
    fontSize: ['17px', '17px', '13px', '13px', '17px'],
    fontWeight: 500,
    lineHeight: ['20px', '20px', '20px'],
    fontFamily: 'ITC'
  },
  smallTitle: {
    fontSize: ['18px', '20px', '22px'],
    fontWeight: 500,
    lineHeight: ['24px', '26px', '28px'],
    fontFamily: 'ITC'
  },
  sectionHeader: {
    fontSize: ['30px', '30px', '30px', '30px', '42px'],
    fontWeight: 500,
    lineHeight: ['36px', '36px', '36px', '30px', '50.4px'],
    fontFamily: 'ITC'
  },
  // Not in design
  homeDealText: {
    fontSize: ['16px', '17px', '18px'],
    fontWeight: 500,
    lineHeight: ['20px', '21px', '22px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  homeDealTextSmall: {
    fontSize: ['8px', '9px', '9px'],
    fontWeight: 600,
    lineHeight: ['20px', '21px', '22px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  // Not in design
  homeDealTitle: {
    fontSize: ['50px', '54px', '60px'],
    fontWeight: 500,
    lineHeight: ['68px', '72px', '78px'],
    fontFamily: 'ITC'
  },
  homeDealTitleSmall: {
    fontSize: ['18px', '18px', '18px'],
    fontWeight: 500,
    lineHeight: ['25px'],
    fontFamily: 'ITC'
  },
  homeDealTitleMedium: {
    fontSize: ['16px', '25px', '25px'],
    fontWeight: 500,
    lineHeight: ['30px'],
    fontFamily: 'ITC'
  },
  saleText: {
    fontSize: ['11px', '15px', '16px'],
    fontWeight: 700,
    lineHeight: ['19px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  saleTextMedium: {
    fontSize: ['18px', '20px', '20px'],
    fontWeight: 700,
    lineHeight: ['19px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  productItemText: {
    fontSize: ['12px', '14px', '15px', '16px'],
    fontWeight: 700,
    lineHeight: ['18px', '22px', '23px', '24px'],
    fontFamily: 'DM Sans Bold'
  },
  productItemTextDecorator: {
    fontSize: ['10px', '14px', '15px', '17px'],
    fontWeight: 500,
    lineHeight: ['18px', '22px', '23px', '24px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  extraLargeBody: {
    fontSize: ['17px', '18px', '19px'],
    fontWeight: 700,
    lineHeight: ['22px', '23px', '24px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  shopText: {
    fontSize: ['18px', '19px', '20px'],
    fontWeight: 500,
    lineHeight: ['24px', '25px', '26px'],
    fontFamily: 'ITC'
  },
  listHeaderSmall: {
    fontSize: ['16px', '12px', '13px', '14px'],
    fontWeight: 600,
    lineHeight: ['13px', '13px', '14px'],
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  homeDealDate: {
    fontSize: ['32px', '36px', '40px'],
    fontWeight: 500,
    lineHeight: ['44px', '48px', '52px'],
    fontFamily: 'ITC'
  },
  homeDealDateSmall: {
    fontSize: ['18px', '20px', '20px'],
    fontWeight: 600,
    lineHeight: ['25px'],
    fontFamily: 'ITC'
  },
  brandTrandingSubtitle: {
    fontSize: ['18px', '24px', '24px', '36px'],
    fontWeight: 500,
    lineHeight: ['24px', '30px', '30px', '42px'],
    fontFamily: 'ITC'
  },
  ourStoryTitleMedium: {
    fontSize: ['32px', '35px', '38px'],
    fontWeight: 500,
    lineHeight: ['42px', '45px', '48px'],
    fontFamily: 'ITC'
  },
  ourStoryTitle: {
    fontSize: ['20px', '22px', '24px'],
    fontWeight: 500,
    lineHeight: ['24px', '26px', '28px'],
    fontFamily: 'ITC'
  },
  ourStoryTitleLarge: {
    fontSize: ['50px', '60px', '70px', '80px'],
    fontWeight: 500,
    lineHeight: ['60px', '70px', '80px', '98px'],
    fontFamily: 'ITC'
  },
  ourStoryText: {
    fontSize: ['16px', '16px', '18px'],
    fontWeight: 400,
    lineHeight: ['22px', '23px', '24px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  ourStoryTextMedium: {
    fontSize: ['17px', '19px', '21px'],
    fontWeight: 400,
    lineHeight: ['34px', '36px', '38px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  // Not in design
  topicSmall: {
    fontSize: ['18px', '18px', '18px'],
    fontWeight: 400,
    lineHeight: ['19px', '20px', '21px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  // Not in design
  blogTitleLarge: {
    fontSize: ['20px', '18px', '28px', '35px'],
    fontWeight: 600,
    lineHeight: ['30px', '32px', '34px', '36px'],
    fontFamily: 'ITC'
  },
  // Not in design
  blogTitle: {
    fontSize: ['24px', '26px', '28px'],
    fontWeight: 500,
    lineHeight: ['32px', '34px', '36px'],
    fontFamily: 'ITC'
  },
  // Not in design
  blogTitleMedium: {
    fontSize: ['20px', '21px', '22px'],
    fontWeight: 500,
    lineHeight: ['24px', '25px', '26px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  productTitle: {
    fontSize: ['30px', '33px', '36px'],
    fontWeight: 500,
    lineHeight: ['42px', '45px', '48px'],
    fontFamily: 'ITC'
  },
  productBrandTitle: {
    fontSize: ['21px', '22px', '25px'],
    fontWeight: 500,
    lineHeight: ['28px', '30px', '32px'],
    fontFamily: 'ITC'
  },
  productTitleMediumBold: {
    fontSize: ['20px', '20px', '20px'],
    fontWeight: 500,
    lineHeight: ['24px'],
    fontFamily: 'ITC'
  },
  listHeaderLarge: {
    fontSize: ['24px'],
    fontWeight: 500,
    lineHeight: ['29px'],
    fontFamily: 'ITC'
  },
  // 56 62
  brandTrandingTitle: {
    fontSize: ['14px', '16px', '20px', '45px'],
    fontWeight: 500,
    lineHeight: ['20px', '21px', '26px', '50px'],
    fontFamily: 'ITC'
  },

  // End checked lists

  // TODO - Use later, temporarily hide this
  footerText: {
    fontSize: ['14px', '14px', '14px'],
    fontWeight: 400,
    lineHeight: ['24px'],
    fontFamily: 'DM Sans, sans-serif'
  },

  listItem: {
    fontSize: ['16px', '14px', '13px', '14px'],
    fontWeight: 400,
    lineHeight: ['24px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  listItemSmall: {
    fontSize: ['14px'],
    fontWeight: 400,
    lineHeight: ['24px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  linkTextUnderline: {
    fontSize: ['22px', '22px', '22px'],
    fontWeight: 500,
    lineHeight: ['26.4px', '26.4px', '26.4px'],
    fontFamily: 'ITC'
  },
  sectionHeaderText: {
    fontSize: ['18px', '18px', '18px'],
    fontWeight: 400,
    lineHeight: ['28px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  filterText: {
    fontSize: ['18px', '18px', '18px'],
    fontWeight: 700,
    lineHeight: ['24px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  productTitleMedium: {
    fontSize: ['18px', '19px', '20px'],
    fontWeight: 500,
    lineHeight: ['22px', '23px', '24px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  selectionOptionText: {
    fontSize: ['16px', '16px', '16px'],
    fontWeight: 400,
    lineHeight: ['16px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  productTitleSmall: {
    fontSize: ['16px'],
    fontWeight: 400,
    lineHeight: ['21px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  productDetailSmall: {
    fontSize: ['18px'],
    fontWeight: 400,
    lineHeight: ['21px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  productTitleExtraSmall: {
    fontSize: ['16px'],
    fontWeight: 400,
    lineHeight: ['16px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  serviceTitleSmall: {
    fontSize: ['15px'],
    fontWeight: 600,
    lineHeight: ['24px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  serviceTextLarge: {
    fontSize: ['30px'],
    fontWeight: 400,
    lineHeight: ['24px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  serviceTextMedium: {
    fontSize: ['15px'],
    fontWeight: 400,
    lineHeight: ['24px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  textTag: {
    fontSize: ['12px', '16px', '16px'],
    fontWeight: 500,
    lineHeight: ['15px'],
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  titleBrandLarge: {
    fontSize: ['70px', '40px', '90px'],
    fontWeight: 600,
    lineHeight: ['25px'],
    fontFamily: 'DM Sans, sans-serif'
  },

  // Not in design
  loginTitle: {
    fontSize: ['27px', '27px', '28px'],
    fontWeight: 600,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'ITC'
  },
  loginText: {
    fontSize: ['16px', '16px', '16px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  loginTextSmall: {
    fontSize: ['13px', '13px', '13px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },

  // Not in design
  accountTitle: {
    fontSize: ['28px', '28px', '28px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'ITC'
  },
  accountLarge: {
    fontSize: ['22px', '22px', '22px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  accountLargeBold: {
    fontSize: ['22px', '22px', '22px'],
    fontWeight: 700,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  accountMedium: {
    fontSize: ['17px', '17px', '17px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  accountMediumBold: {
    fontSize: ['17px', '17px', '17px'],
    fontWeight: 700,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  accountSmall: {
    fontSize: ['13px', '13px', '13px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },

  productSanity: {
    fontSize: ['13px', '13px', '13px'],
    fontWeight: 400,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  recentPostTitle: {
    fontSize: ['32px', '32px', '32px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'ITC'
  },
  recentPostText: {
    fontSize: ['17px', '17px', '17px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  recentPostDateText: {
    fontSize: ['14px', '14px', '14px'],
    fontWeight: 400,
    lineHeight: ['18px', '19px', '20px'],
    fontFamily: 'DM Sans, sans-serif'
  },
  iconText: {
    fontSize: ['16px', '17px', '18px'],
    fontWeight: 800,
    lineHeight: ['22px', '23px', '24px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  blogBodyText: {
    // fontSize: ['18px', '18px', '18px'],
    // fontWeight: 800,
    // lineHeight: ['22px', '23px', '24px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  reviewText: {
    fontSize: ['23px', '23px', '23px'],
    fontWeight: 400,
    lineHeight: ['22px', '23px', '24px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  notiShippingText: {
    fontSize: ['14px', '15px', '16px'],
    fontWeight: 400,
    lineHeight: ['20px', '20px', '21px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  viewMoreText: {
    fontSize: ['20px', '20px', '20px'],
    fontWeight: 500,
    lineHeight: ['20px', '20px', '21px'],
    fontFamily: 'ITC'
  },

  //point system
  sectionHeaderPoint: {
    fontSize: ['25px', '30px', '30px', '30px', '37px', '42px'],
    fontWeight: 500,
    lineHeight: ['31px', '36px', '36px', '36px', '43px', '50.4px'],
    fontFamily: 'ITC'
  },
  sectionHeaderPointUnlock: {
    fontSize: ['25px', '30px', '30px', '30px', '37px', '38.5px'],
    fontWeight: 500,
    lineHeight: ['31px', '36px', '36px', '36px', '43px', '48px'],
    fontFamily: 'ITC'
  },
  customerPointAvailable: {
    fontSize: ['18px', '25px', '36px', '36px', '36px'],
    fontWeight: 500,
    lineHeight: ['25px', '32px', '43.2px', '43.2px', '43.2px'],
    fontFamily: 'ITC'
  },
  customerPointExpiring: {
    fontSize: ['14px', '15px', '20px', '20px', '20px'],
    fontWeight: 500,
    lineHeight: ['18px', '19px', '24px', '24px', '24px'],
    fontFamily: 'ITC'
  },
  customerPointMember: {
    fontSize: ['28px', '34px'],
    fontWeight: 400,
    lineHeight: ['38px', '44.27px'],
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  rewardList: {
    fontSize: ['28px'],
    lineHeight: ['33.6px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  claimVoucher: {
    fontSize: ['24px'],
    lineHeight: ['31.25px'],
    fontWeight: 400,
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  voucherPoint: {
    fontSize: ['25px', '25px', '25px', '30px', '30px', '36px'],
    lineHeight: ['32px', '32px', '32px', '37px', '37px', '43.2px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  voucherPointSmall: {
    fontSize: ['24px'],
    lineHeight: ['28.8px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  voucherPointText: {
    fontSize: ['18px'],
    fontWeight: 500,
    lineHeight: ['21.6px'],
    fontFamily: 'ITC'
  },
  voucherMember: {
    fontSize: ['25px'],
    lineHeight: ['30px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  voucherMemberText: {
    fontSize: ['13px'],
    lineHeight: ['15.6px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  voucherNotStackable: {
    fontSize: ['8px', '8px', '7px', '10px'],
    lineHeight: ['10px', '10px', '9px', '12px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  unlockItemTitle: {
    fontSize: ['30px', '30px', '30px', '30px', '36px'],
    lineHeight: ['41px', '41px', '41px', '41px', '46.87px'],
    fontWeight: 400,
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  unlockItemSubTitle: {
    fontSize: ['22px'],
    lineHeight: ['28.04px'],
    fontWeight: 400,
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  unlockItemSubTitleLag: {
    fontSize: ['23px'],
    lineHeight: ['29px'],
    fontWeight: 400,
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  termsConditions: {
    fontSize: ['18px'],
    lineHeight: ['26px'],
    fontWeight: 400,
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  rewardTiersThead: {
    fontSize: ['8px', '15px', '20px', '20px', '28px', '28px'],
    lineHeight: ['14px', '21px', '26px', '26px', '34px', '34px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  rewardTiersTbodyText: {
    fontSize: ['6px', '12px', '15px', '15px', '20px'],
    lineHeight: ['12px', '18px', '21px', '21px', '26.4px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },

  //advocado
  joinMembership: {
    fontSize: ['9px', '9px', '9px', '9px', '13px'],
    lineHeight: ['9px', '9px', '9px', '9px', '13px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },
  sectionHeaderPdpa: {
    fontSize: ['30px', '30px', '30px', '30px', '54px'],
    fontWeight: 500,
    lineHeight: ['36px', '36px', '36px', '36px', '60.4px'],
    fontFamily: 'ITC'
  },
  sectionHeaderPdpaBold: {
    fontSize: ['30px', '30px', '30px', '40px'],
    fontWeight: 600,
    lineHeight: ['36px', '36px', '36px', '46px'],
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  sectionHeaderPdpaBoldSmall: {
    fontSize: ['30px', '30px', '30px', '30px'],
    fontWeight: 600,
    lineHeight: ['36px', '36px', '36px', '36px'],
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  titlePdpa: {
    fontSize: ['30px', '30px', '30px', '25px'],
    lineHeight: ['41px', '41px', '41px', '36px'],
    fontWeight: 400,
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  termsConditionsPdpa: {
    fontSize: ['18px'],
    lineHeight: ['25px'],
    fontWeight: 400,
    fontFamily: 'DM Sans Regular, sans-serif'
  },

  //contact-us
  contactUsContent: {
    fontSize: ['18px', '25px', '25px', '25px', '25px'],
    lineHeight: ['26px', '33px', '33px', '33px', '33px'],
    fontWeight: 500,
    fontFamily: 'DM Sans Regular, sans-serif'
  },
  contactUsContentBold: {
    fontSize: ['18px', '25px', '25px', '25px', '25px'],
    lineHeight: ['26px', '33px', '33px', '33px', '33px'],
    fontWeight: 700,
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  contactUsContentBoldLarge: {
    fontSize: ['30px', '36px', '36px', '36px', '36px'],
    lineHeight: ['38px', '44px', '44px', '44px', '44px'],
    fontWeight: 700,
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  contactUsContentBoldMedium: {
    fontSize: ['25px', '30px', '30px', '30px', '30px'],
    lineHeight: ['33px', '38px', '38px', '38px', '38px'],
    fontWeight: 700,
    fontFamily: 'DM Sans Bold, sans-serif'
  },

  //footer info
  tcTitle: {
    fontSize: ['21px', '23px', '23px', '23px', '23px'],
    lineHeight: ['29px', '31px', '31px', '31px', '31px'],
    fontWeight: 700,
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  tcTitleLarge: {
    fontSize: ['21px', '25px', '25px', '25px', '32px'],
    lineHeight: ['29px', '33px', '33px', '33px', '38px'],
    fontWeight: 700,
    fontFamily: 'DM Sans Bold, sans-serif'
  },
  tcText: {
    fontSize: ['22px', '25px', '25px', '25px', '22px'],
    lineHeight: ['30px', '33px', '33px', '33px', '33px'],
    fontWeight: 500,
    fontFamily: 'DM Sans Regular, sans-serif'
  },

  //jml logo
  jmlText: {
    fontSize: ['18px', '22px', '24px', '24px', '24px'],
    lineHeight: ['28px', '30px', '32px', '32px', '32px'],
    fontWeight: 500,
    fontFamily: 'DM Sans, sans-serif'
  },

  //text icon reel
  iconReel: {
    fontSize: ['12px', '13px', '14px', '14px', '14px'],
    lineHeight: ['18px', '19px', '20px', '20px', '20px'],
    fontWeight: 500,
    fontFamily: 'DM Sans, sans-serif'
  },
  titleReel: {
    fontSize: ['10px', '18px', '20px', '20px', '20px'],
    lineHeight: ['16px', '24px', '26px', '26px', '26px'],
    fontWeight: 500,
    fontFamily: 'ITC'
  },

  //usp brand
  uspDescription: {
    lineHeight: ['28px', '28px', '32px', '32px', '32px'],
    fontSize: ['16px', '16px', '20px', '20px', '20px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  uspTitleSub: {
    lineHeight: ['28px', '28px', '32px', '32px', '32px'],
    fontSize: ['20px', '20px', '24px', '24px', '24px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  uspTitle: {
    lineHeight: ['28px', '28px', '32px', '32px', '32px'],
    fontSize: ['28px', '28px', '32px', '32px', '32px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },

  //cheongdam brand
  exclusiveText: {
    lineHeight: ['20px', '25px', '25px', '32px', '32px'],
    fontSize: ['20px', '25px', '25px', '32px', '32px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  exclusiveTitle: {
    lineHeight: ['30px', '35px', '40px', '50px', '50px'],
    fontSize: ['30px', '35px', '40px', '50px', '50px'],
    fontWeight: 700,
    fontFamily: 'ITC'
  },
  participateTitle: {
    lineHeight: ['18px', '20px', '20px', '22px', '24px'],
    fontSize: ['18px', '20pxpx', '20px', '22px', '24px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  outletText: {
    lineHeight: ['15px', '15px', '15px', '15px', '15px'],
    fontSize: ['15px', '15px', '15px', '15px', '15px'],
    fontWeight: 500,
    fontFamily: 'DM Sans, sans-serif'
  },
  outletTitle: {
    lineHeight: ['16px', '16px', '16px', '17px', '18px'],
    fontSize: ['16px', '16px', '16px', '17px', '18px'],
    fontWeight: 600,
    fontFamily: 'DM Sans, sans-serif'
  },
  fastResultTitleWhite: {
    lineHeight: ['8px', '14px', '18px'],
    fontSize: ['8px', '14px', '18px'],
    fontWeight: 600,
    fontFamily: 'DM Sans, sans-serif'
  },
  fastResultTitle: {
    lineHeight: ['30px', '40px', '40px', '45px', '50px'],
    fontSize: ['30px', '40px', '40px', '45px', '50px'],
    fontWeight: 600,
    fontFamily: 'DM Sans, sans-serif'
  },
  fastResultText: {
    lineHeight: ['15px', '15px', '15px', '20px', '20px'],
    fontSize: ['15px', '15px', '15px', '20px', '20px'],
    fontWeight: 600,
    fontFamily: 'ITC'
  },
  videosTitle: {
    lineHeight: ['20px', '22px', '22px', '25px', '25px'],
    fontSize: ['20px', '22px', '22px', '25px', '25px'],
    fontWeight: 600,
    fontFamily: 'DM Sans, sans-serif'
  },
  certificationTitle: {
    lineHeight: ['18px', '24px', '30px'],
    fontSize: ['16px', '20px', '26px'],
    fontWeight: 900,
    fontFamily: 'ITC, sans-serif'
  },
  certificationText: {
    lineHeight: ['10px', '18px', '20px'],
    fontSize: ['8px', '14px', '16px'],
    fontWeight: 500,
    fontFamily: 'DM Sans, sans-serif'
  },
  tipsTitle: {
    lineHeight: ['20px', '22px', '22px', '25px', '25px'],
    fontSize: ['20px', '22px', '22px', '25px', '25px'],
    fontWeight: 600,
    fontFamily: 'ITC'
  },
  tipsText: {
    lineHeight: ['16px', '17px', '18px', '18px', '18px'],
    fontSize: ['16px', '17px', '18px', '18px', '18px'],
    fontWeight: 500,
    fontFamily: 'DM Sans, sans-serif'
  },
  qaTitleMax: {
    lineHeight: ['30px', '35px', '84px', '100px'],
    fontSize: ['30px', '35px', '80px', '100px'],
    fontWeight: 700,
    fontFamily: 'ITC'
  },
  qaTitleQA: {
    lineHeight: ['70px', '80px', '90px', '130px'],
    fontSize: ['60px', '70px', '80px', '120px'],
    fontWeight: 700,
    fontFamily: 'ITC'
  },
  qaTitle: {
    lineHeight: ['34px', '44px', '44px', '54px'],
    fontSize: ['30px', '40px', '40px', '50px'],
    fontWeight: 700,
    fontFamily: 'ITC'
  },
  qaQuestion: {
    lineHeight: ['22px', '22px', '24px', '24px'],
    fontSize: ['18px', '18px', '20px', '20px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  qaAnswer: {
    lineHeight: ['22px', '22px', '24px'],
    fontSize: ['18px', '18px', '20px'],
    fontWeight: 600,
    fontFamily: 'DM Sans, sans-serif'
  },

  //doctorcos brand
  docMainTitle: {
    lineHeight: ['18px', '24px', '30px'],
    fontSize: ['16px', '20px', '25px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  docSubTitle: {
    lineHeight: ['20px', '24px', '28px'],
    fontSize: ['16px', '20px', '24px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  docInfo: {
    lineHeight: '16px',
    fontSize: '13px',
    fontWeight: 400
  },
  docPercentTitle: {
    lineHeight: ['18px', '20px', '28px'],
    fontSize: ['16px', '18px', '25px'],
    fontWeight: 400
  },
  docKeyIngredient: {
    lineHeight: '16px',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  docKeyIngredientTitle: {
    lineHeight: ['18px', '20px', '24px'],
    fontSize: ['14px', '16px', '16px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  docReviewTitle: {
    lineHeight: ['28px', '32px', '37px'],
    fontSize: ['24px', '28px', '30px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  docReviewItemTitle: {
    lineHeight: ['16px', '18px', '20px'],
    fontSize: ['12px', '12px', '14px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  docReviewItemDesc: {
    lineHeight: ['16px', '18px', '20px'],
    fontSize: ['12px', '12px', '14px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  docOrderTitle: {
    lineHeight: ['50px', '64px', '84px'],
    fontSize: ['40px', '50px', '70px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  docQAQuestion: {
    lineHeight: ['18px', '21px', '21px'],
    fontSize: ['14px', '16px', '16px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  docQAAnswer: {
    lineHeight: ['17px', '19px', '19px'],
    fontSize: ['12px', '14px', '14px'],
    fontWeight: 400
  },

  //grip tumbler
  gripMainTitle: {
    lineHeight: ['24px', '28px', '36px', '46px', '54px'],
    fontSize: ['20px', '24px', '30px', '40px', '48px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  gripContentLarge: {
    lineHeight: ['32px', '40px', '44px', '56px', '60px', '62px'],
    fontSize: ['26px', '32px', '38px', '50px', '54px', '56px'],
    fontWeight: 500,
    fontFamily: 'Inter'
  },
  gripContentSmall: {
    lineHeight: ['14px', '18px', '20px', '28px', '30px', '40px'],
    fontSize: ['12px', '14px', '16px', '22px', '24px', '28px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  gripStabilityTitle: {
    lineHeight: ['12px', '18px', '28px'],
    fontSize: ['12px', '14px', '16px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  gripHeroTitle: {
    lineHeight: ['16px', '18px', '24px', '32px', '38px'],
    fontSize: ['16px', '18px', '24px', '32px', '38px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  gripReviewItemDesc: {
    lineHeight: ['16px', '18px', '20px', '26px', '30px'],
    fontSize: ['12px', '12px', '14px', '18px', '20px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  gripProblemTitle: {
    lineHeight: ['28px', '32px', '40px', '42px', '48px', '52px'],
    fontSize: ['24px', '28px', '34px', '38px', '44px', '48px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  gripInsulationContent: {
    lineHeight: ['14px', '18px', '20px', '24px', '30px', '40px'],
    fontSize: ['12px', '14px', '16px', '20px', '24px', '28px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },

  //oralcare
  oralcareMainTitle: {
    lineHeight: ['24px', '28px', '36px', '46px', '54px'],
    fontSize: ['20px', '24px', '30px', '40px', '48px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  oralcareHeroTitle: {
    lineHeight: ['30px', '36px', '44px', '46px', '54px', '60px'],
    fontSize: ['24px', '30px', '38px', '40px', '48px', '56px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  oralcareFeaTittle: {
    lineHeight: ['13px', '13px', '16px', '18px'],
    fontSize: ['11px', '11px', '12px', '14px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  oralcareFeaTittleLarge: {
    lineHeight: ['13px', '18px', '18px', '20px'],
    fontSize: ['11px', '14px', '14px', '18px'],
    fontWeight: 600,
    fontFamily: 'Inter'
  },
  oralcareTittle: {
    lineHeight: ['14px', '18px', '16px', '22px'],
    fontSize: ['12px', '14px', '12px', '18px'],
    fontWeight: 600,
    fontFamily: 'Inter'
  },
  oralcareEliminationTittle: {
    lineHeight: ['28px', '34px', '44px', '46px', '54px', '60px'],
    fontSize: ['22px', '28px', '38px', '40px', '48px', '56px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  oralcareEliminationContent: {
    lineHeight: ['14px', '20px', '24px', '28px'],
    fontSize: ['12px', '16px', '20px', '24px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  oralcareEffectivenessTittle: {
    lineHeight: ['28px', '34px', '44px', '46px', '54px', '60px'],
    fontSize: ['22px', '28px', '38px', '40px', '48px', '56px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  oralcareOptimizingTitle: {
    lineHeight: ['24px', '28px', '32px', '34px'],
    fontSize: ['20px', '24px', '28px', '30px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  oralcareOptimizingContent: {
    lineHeight: ['18px', '20px', '22px', '24px'],
    fontSize: ['14px', '16px', '18px', '20px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  oralcareKeyIngredient: {
    lineHeight: '16px',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  oralcareKeyIngredientTitle: {
    lineHeight: ['18px', '20px', '24px'],
    fontSize: ['14px', '16px', '18px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  oralcareHTUTittle: {
    lineHeight: ['28px', '34px', '42px'],
    fontSize: ['22px', '28px', '36px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  oralcareReviewItemTitle: {
    lineHeight: ['20px', '22px', '24px'],
    fontSize: ['14px', '16px', '18px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  oralcareReviewItemDesc: {
    lineHeight: ['20px', '20px', '22px'],
    fontSize: ['14px', '14px', '16px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  oralcareDealsTittle: {
    lineHeight: ['25px', '34px', '44px'],
    fontSize: ['19px', '26px', '38px'],
    fontWeight: 800,
    fontFamily: 'Inter'
  },
  oralcareQAQuestion: {
    lineHeight: ['18px', '21px', '23px'],
    fontSize: ['14', '16px', '18px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  oralcareQAAnswer: {
    lineHeight: ['17px', '19px', '22px'],
    fontSize: ['14px', '16px', '16px'],
    fontWeight: 400
  },
  oralcareQATitle: {
    lineHeight: ['28px', '32px', '34px'],
    fontSize: ['20px', '28pxpx', '32px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  gripExclusiveOffer: {
    lineHeight: ['14px', '16px', '18px', '22px', '26px'],
    fontSize: ['12px', '12px', '14px', '18px', '20px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  gripExclusiveOfferTitle: {
    lineHeight: ['16px', '20px', '22px', '24px', '26px'],
    fontSize: ['14px', '16px', '18px', '22px', '24px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  gripExclusiveOfferTitleLarge: {
    lineHeight: ['18px', '22px', '24px', '28px', '30px'],
    fontSize: ['16px', '18px', '20px', '26px', '28px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  oralcareParticipateTitle: {
    lineHeight: ['24px', '26px', '28px', '30px', '32px'],
    fontSize: ['24px', '26px', '28px', '30px', '32px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },

  // jml
  jmlFeatureSetTitle: {
    lineHeight: ['12px', '14px', '16px', '18px', '20px'],
    fontSize: ['12px', '14px', '16px', '18px', '20px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  jmlFeatureSetDescription: {
    lineHeight: ['12px', '14px', '16px', '18px', '20px'],
    fontSize: ['12px', '14px', '16px', '18px', '20px'],
    fontWeight: 400,
    fontFamily: 'DM Sans, sans-serif'
  },
  JmllinkItem: {
    fontSize: ['18px', '18px', '18px', '18px', '18px'],
    fontWeight: 600,
    lineHeight: ['20px', '20px', '18px'],
    fontFamily: 'ITC'
  },

  // supplement
  supplementHeroTitle: {
    lineHeight: ['30px', '36px', '44px', '46px', '86px', '86px'],
    fontSize: ['24px', '30px', '38px', '40px', '86px', '86px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementHeroSubTitle: {
    lineHeight: ['27px', '27px', '27px', '27px', '27px'],
    fontSize: ['27px', '27px', '27px', '27px', '27px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementFeature: {
    lineHeight: ['22px', '22px', '24px', '27px', '30px'],
    fontSize: ['22px', '22px', '24px', '27px', '30px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementFeatureDesc: {
    lineHeight: ['16px', '16px', '16px', '16px', '16px'],
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementBodyTitle: {
    lineHeight: ['30px', '36px', '44px', '46px', '54px'],
    fontSize: ['24px', '30px', '38px', '40px', '54px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementBodySubTitle: {
    lineHeight: ['20px', '20px', '25px', '25px', '25px'],
    fontSize: ['20px', '20px', '25px', '25px', '25px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },

  supplementProductCardTitleJP: {
    lineHeight: ['37px', '37px', '37px', '37px', '40px'],
    fontSize: ['31px', '31px', '31px', '31px', '34px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementProductCardTitleEN: {
    lineHeight: ['29px', '29px', '29px', '29px', '34px'],
    fontSize: ['22px', '22px', '22px', '22px', '25px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementProductCardSubTitle: {
    lineHeight: ['27px', '27px', '27px', '25px', '30px'],
    fontSize: ['19px', '19px', '19px', '17px', '21px'],
    fontWeight: 700,
    fontFamily: 'Inter'
  },
  supplementProductCardDescJP: {
    lineHeight: ['20px', '20px', '20px', '18px', '25px'],
    fontSize: ['15px', '15px', '15px', '14px', '20px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementProductCardDescEN: {
    lineHeight: ['22px', '22px', '22px', '20px', '25px'],
    fontSize: ['15px', '15px', '15px', '14px', '18px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementProductCardBenifitTitleEN: {
    lineHeight: ['15px', '15px', '15px', '15px', '15px'],
    fontSize: ['15px', '15px', '15px', '14px', '15px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementProductCardBenifitTitleJP: {
    lineHeight: ['15px', '15px', '15px', '15px', '15px'],
    fontSize: ['15px', '15px', '15px', '15px', '15px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementProductCardBenifitDescJP: {
    lineHeight: ['15px', '15px', '15px', '15px', '15px'],
    fontSize: ['15px', '15px', '15px', '15px', '15px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementProductCardBenifitDescEN: {
    lineHeight: ['15px', '15px', '15px', '15px', '15px'],
    fontSize: ['15px', '15px', '15px', '15px', '15px'],
    fontWeight: 400,
    fontFamily: 'Inter'
  },
  supplementParticipateTitle: {
    lineHeight: ['26px', '28px', '30px', '34px', '36px'],
    fontSize: ['26px', '28px', '30px', '34px', '36px'],
    fontWeight: 700,
    fontFamily: 'DM Sans, sans-serif'
  },
  supplementCertificationTitle: {
    lineHeight: ['36px', '40px', '50px', '54px', '62px'],
    fontSize: ['36px', '40px', '50px', '54px', '62px'],
    fontWeight: 400,
    fontFamily: 'ITC'
  },
  supplementCertificationDesc: {
    fontSize: ['18px', '18px', '18px', '18px', '18px'],
    lineHeight: ['25px', '25px', '25px', '25px', '25px'],
    fontWeight: 400,
    fontFamily: 'ITC'
  }
}

export default textStyles
