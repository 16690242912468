import React, { Fragment } from 'react'
import Script from 'next/script'

const MetaPixelScript = () => {
  if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
    return null
  }

  return (
    <>
      <Script id="meta-pixel">
        {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                window.fbq=fbq;
                fbq('init', '754629182698828');
                fbq('track', 'PageView');
            `}
      </Script>
      <Script id="facebook-meta-pixel">
        {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                window.fbq=fbq;
                fbq('init', '1727653260982386');
                fbq('track', 'PageView');
            `}
      </Script>
    </>
  )
}

export default MetaPixelScript
