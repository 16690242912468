import Medusa from '@medusajs/medusa-js'
import { QueryClient } from '@tanstack/react-query'
import axios, { AxiosRequestConfig } from 'axios'
import { createClient } from '@sanity/client'

const MEDUSA_BACKEND_URL = process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL || ''

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
      retry: 1
    }
  }
})

const medusaClient = new Medusa({ baseUrl: MEDUSA_BACKEND_URL, maxRetries: 3 })

export function medusaRequest(
  method: string,
  path = '',
  payload = {},
  config: AxiosRequestConfig = {}
) {
  const options = {
    method,
    withCredentials: true,
    url: path,
    data: payload,
    json: true,
    ...config
  }
  return axios.create({ baseURL: MEDUSA_BACKEND_URL })(options)
}

const sanityClient = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'projectId',
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'dataset',
  token: process.env.NEXT_PUBLIC_SANITY_TOKEN || '',
  useCdn: true
})

export { MEDUSA_BACKEND_URL, queryClient, medusaClient, sanityClient }
