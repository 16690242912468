import { BACKEND_URL } from '@constants/server.constant'

export const IS_BROWSER = typeof window !== 'undefined'

const AVOCADO_HOST = process.env.NEXT_PUBLIC_AVOCADO_HOST

export const AVOCADO_CONSTANT = {
  get_token:
    process.env.NEXT_PUBLIC_MEDUSA_BACKEND_URL + '/store/avocado-token',
  get_userinfo:
    AVOCADO_HOST +
    '/api/v1.1/customers/phone-number/${phone_code}/${phonenumber}/compact',
  create_customer: AVOCADO_HOST + '/api/v1.1/customers/',
  get_pdpa: AVOCADO_HOST + '/api/v1.1/merchants/pdpa',
  accept_pdpa: AVOCADO_HOST + '/api/v1.1/customers/pdpa',
  get_obtain_campaign: AVOCADO_HOST + '/api/v1.1/campaigns/list',
  apply_avocado: BACKEND_URL + '/store/apply-avocado',
  void_avocado: BACKEND_URL + '/store/void-avocado',
  get_all_products_by_brand: BACKEND_URL + '/store/brand/products',
  forgot_password: BACKEND_URL + '/store/advocado/forgot-pin'
}

export const MAILCHIMP = {
  subscription: BACKEND_URL + '/mailchimp/subscribe',
  subscription_jml: BACKEND_URL + '/mailchimp/subscribe-jml'
}

export const CAPCHA = {
  siteverify: '/api/enquiry'
}

export const MAX_ITEMS_NUMBER = 4
