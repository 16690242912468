import { ItemGa4, PromotionGA4 } from '@common/interface/ga4'
import { IProductItem } from '@common/interface/product'
import { Cart, Discount, LineItem, LineItemAdjustment } from '@medusajs/medusa'
import { Promotions } from 'types/global'

const checkAdjusmentIsAdvocado = (adj: LineItemAdjustment) => {
  return adj.description.toLowerCase() === 'advocado' && adj.metadata != null
}

export const mapItemProductToItemGa4 = (items: IProductItem[]) => {
  let itemsGa4: ItemGa4[] = []

  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const variants = items[i].variants

    const isGa4: ItemGa4[] = variants.map((variant) => ({
      item_id: variant.sku,
      item_name: item.title,
      item_variant: variant.title,
      quantity: variant.inventory_quantity,
      price: (variant as any).calculated_price / 100
    }))

    itemsGa4 = itemsGa4.concat(isGa4)
  }

  return itemsGa4
}

export const mapItemCartToItemGa4 = (items: LineItem[]) => {
  const itemsGa4: ItemGa4[] = []

  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const variant = items[i].variant

    const coupon = item.adjustments
      .map((adj) => {
        if (!checkAdjusmentIsAdvocado(adj)) {
          return adj.description
            .split(',')[0]
            ?.replace('promotion:', '')
            ?.trim()
        } else {
          return adj.metadata.name ?? 'advocado'
        }
      })
      .join(',')

    itemsGa4.push({
      item_id: variant.sku,
      item_name: item.title,
      item_variant: variant.title,
      quantity: item.quantity,
      price: (item.total ?? 0) / 100,
      discount: (item.discount_total ?? 0) / 100,
      coupon
    })
  }

  return itemsGa4
}

export const convertItemsAdvocadoToAdvocado = (items: LineItem[]): any[] => {
  let advocados: any[] = []

  for (let i = 0; i < items.length; i++) {
    const adjsMetadata = items[i].adjustments
      .filter((adj) => checkAdjusmentIsAdvocado(adj))
      .map((adj) => adj.metadata)

    advocados = advocados.concat(adjsMetadata)
  }

  return Array.from(new Set(advocados))
}

export const mapToPromotionGA4 = (
  items: LineItem[],
  promotions?: Promotions[],
  discounts?: Discount[]
) => {
  const promotionsGA4: PromotionGA4[] = []

  if (promotions != null && promotions.length > 0) {
    for (let i = 0; i < promotions.length; i++) {
      const promo = promotions[i]
      const itemsPromo = items.filter(
        (item) =>
          !!item.adjustments.find(
            (adj) => (adj as any).promotion_id === promo.id
          )
      )

      const itemsPromoGa4 = mapItemCartToItemGa4(itemsPromo)

      promotionsGA4.push({
        promotion_name: promo.description,
        promotion_id: promo.id,
        items: itemsPromoGa4
      })
    }
  }
  if (discounts != null && discounts.length > 0) {
    for (let i = 0; i < discounts.length; i++) {
      const discount = discounts[i]
      const itemsDiscount = items.filter(
        (item) =>
          !!item.adjustments.find((adj) => adj.discount_id === discount.id)
      )

      const itemsDiscountGA4 = mapItemCartToItemGa4(itemsDiscount)

      promotionsGA4.push({
        promotion_name: discount.code,
        promotion_id: discount.id,
        items: itemsDiscountGA4
      })
    }
  }

  const advocados = convertItemsAdvocadoToAdvocado(items)

  if (advocados.length > 0) {
    for (let i = 0; i < advocados.length; i++) {
      const advocado = advocados[i]
      const itemsAdvocado = items.filter(
        (item) =>
          !!item.adjustments.find(
            (adj) => adj.metadata?.multi_commit_id === advocado.multi_commit_id
          )
      )
      const itemsAdvocadoGA4 = mapItemCartToItemGa4(itemsAdvocado)

      promotionsGA4.push({
        promotion_name: advocado.name ?? 'advocado',
        promotion_id: advocado.multi_commit_id,
        items: itemsAdvocadoGA4
      })
    }
  }

  return promotionsGA4
}

export const getCouponFromCart = (cart: Cart) => {
  let coupon = ''

  if ((cart as any).promotions != null && (cart as any).promotions.length > 0) {
    coupon += ((cart as any).promotions as Promotions[])
      .map((p) => p.description)
      .join(',')
  }

  if (cart.discounts != null && cart.discounts.length > 0) {
    coupon += cart.discounts.map((d) => d.code).join(',')
  }

  const advocados = convertItemsAdvocadoToAdvocado(cart.items)

  if (advocados.length > 0) {
    coupon += advocados.map((a) => a.name ?? 'Advocado').join(',')
  }

  return coupon
}
