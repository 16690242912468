import { Product } from '@medusajs/medusa'

export enum ReelStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export interface Reel {
  id: string
  title: string
  reel_video: string
  thumbnail: string
  start_date?: Date
  order: number
  sales: number
  add_to_cart: number
  views: number
  likes: number
  shares: number
  unit_sold: number
  status?: string
  products?: Product[]
  social_share?: any
  description: string
}
