import colors from './colors.theme'
import textStyles from './text-styles.theme'

export default {
  baseStyle: {
    borderRadius: 4,
    fontFamily: 'ITC'
  },
  sizes: {
    // 32
    sm: {
      px: 7,
      fontWeight: 500,
      fontFamily: 'ITC'
    },
    // 40
    md: {
      px: 8,
      py: 1,
      // ...textStyles.extraLargeBody,
      fontWeight: 600,
      fontFamily: 'ITC'
    },
    // 56
    lg: {
      minH: '56px',
      height: '56px',
      px: 24,
      ...textStyles.productTitleMedium,
      fontWeight: 500,
      fontFamily: 'ITC'
    },
    // 68
    xl: {
      height: '68px',
      px: '108px',
      fontWeight: 600,
      fontFamily: 'ITC'
    }
  },
  variants: {
    main: {
      transition: '0s',
      // bg: colors.mexicanRed,
      color: colors.white,
      _hover: {
        bg: 'linear-gradient(90deg, #680000 0%,  #A52222 25%, #A52222 75%, #680000 100%)'
      },
      fontFamily: 'ITC'
    },
    dark: {
      bg: colors.black,
      color: colors.white,
      _hover: {
        // bg: colors.mineShaft,
      },
      fontFamily: 'ITC'
    }
  }
  // defaultProps: {
  //   variant: 'main',
  // },
}
