import RouterUrl from '@constants/router.constant'
import { Customer } from '@medusajs/medusa'
import { useMutation } from '@tanstack/react-query'
import { useMeCustomer } from 'medusa-react'
import { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useStore } from './store-context'

export enum LOGIN_VIEW {
  SIGN_IN = 'sign-in',
  REGISTER = 'register'
}

export interface AvocadoUser {
  token?: string
  pin?: string
  avocadoInfo?: any
  phone_num?: string
  phone_code?: string
}

interface AccountContext {
  customer?: Omit<Customer, 'password_hash'>
  avocado?: AvocadoUser
  retrievingCustomer: boolean
  loginView: [LOGIN_VIEW, React.Dispatch<React.SetStateAction<LOGIN_VIEW>>]
  checkSession: () => void
  refetchCustomer: () => void
  handleLogout: () => void
  setAvocado: (value: any) => void
  handleGetAvocadoUser: (token: string) => Promise<void>
}

const AccountContext = createContext<AccountContext | null>(null)

interface AccountProviderProps {
  children?: React.ReactNode
}

const handleDeleteSession = async () => {
  const { medusaClient } = await import('@lib/config')

  return medusaClient.auth.deleteSession()
}

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const {
    customer,
    isLoading: retrievingCustomer,
    refetch,
    remove
  } = useMeCustomer()

  const { getRegion } = useStore()

  const [avocado, setAvocado] = useState<AvocadoUser>({})

  const loginView = useState<LOGIN_VIEW>(LOGIN_VIEW.SIGN_IN)

  const router = useRouter()

  const checkSession = useCallback(() => {
    if (!customer && !retrievingCustomer) {
      router.push('/auth/login')
    }
  }, [customer, retrievingCustomer, router])

  const useDeleteSession = useMutation({
    mutationFn: handleDeleteSession,
    mutationKey: ['delete-session']
  })

  const handleLogout = () => {
    useDeleteSession.mutate(undefined, {
      onSuccess: () => {
        remove()
        loginView[1](LOGIN_VIEW.SIGN_IN)
        router.push(RouterUrl.HOME.ROOT)
        setAvocado({})
      }
    })
  }

  const handleGetAvocadoUser = async (token: string) => {
    try {
      const region = getRegion()

      if (!region) return

      if (!customer?.phone) return

      const { parsePhoneNumberFromString } = await import('libphonenumber-js')

      const parsedNumber = parsePhoneNumberFromString(
        customer?.phone.indexOf('+') != -1
          ? customer?.phone
          : '+' + customer?.phone
      )

      if (!parsedNumber) return
      setAvocado((pre) => ({
        ...pre,
        phone_code: parsedNumber.countryCallingCode,
        phone_num: parsedNumber.nationalNumber
      }))

      const { AVOCADO_CONSTANT } = await import('@lib/constants')

      let url = AVOCADO_CONSTANT.get_userinfo.replace(
        '${phone_code}',
        parsedNumber.countryCallingCode
      )
      url = url.replace('${phonenumber}', parsedNumber.nationalNumber)

      if (!token) return

      const axios = (await import('axios')).default

      const { data } = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })

      if (data.status === 'success') {
        setAvocado((pre) => ({
          ...pre,
          avocadoInfo: data.data
        }))
      } else {
        console.log('Not found avocado user')
      }
    } catch (e) {
      console.log((e as any).message)
    }
  }

  const handleGetAvocadoToken = async () => {
    try {
      const { medusaRequest } = await import('@lib/config')

      const { AVOCADO_CONSTANT } = await import('@lib/constants')

      const { data } = await medusaRequest('GET', AVOCADO_CONSTANT.get_token)

      if (data.status == true) {
        setAvocado((pre) => ({ ...pre, token: data.data }))

        await handleGetAvocadoUser(data.data)
      }
    } catch (e: any) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    if (
      !avocado.token &&
      customer &&
      (customer as any).is_verify_otp === true
    ) {
      handleGetAvocadoToken()
    }
  }, [avocado.token, customer])

  useEffect(() => {
    if (
      customer &&
      ((customer as any).is_verify_otp !== true || customer.phone == null)
    ) {
      router.push(RouterUrl.AUTH.VERIFY_PHONE)
    }
  }, [customer])

  useEffect(() => {
    setInterval(
      () => {
        handleGetAvocadoToken()
      },
      9 * 60 * 1000
    )
  }, [])

  return (
    <AccountContext.Provider
      value={{
        customer,
        retrievingCustomer,
        loginView,
        checkSession,
        refetchCustomer: refetch,
        handleLogout,
        avocado: avocado,
        setAvocado,
        handleGetAvocadoUser
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => {
  const context = useContext(AccountContext)

  if (context === null) {
    throw new Error('useAccount must be used within a AccountProvider')
  }
  return context
}
