import React from 'react'

import { MEDUSA_BACKEND_URL, queryClient } from '@lib/config'
import { AccountProvider } from '@lib/context/account-context'
import { CartDropdownProvider } from '@lib/context/cart-dropdown-context'
import { MobileMenuProvider } from '@lib/context/mobile-menu-context'
import { StoreProvider } from '@lib/context/store-context'
import { Hydrate } from '@tanstack/react-query'
import { CartProvider, MedusaProvider } from 'medusa-react'
import { AppPropsWithLayout } from 'types/global'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@theme/index'
import 'styles/globals.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'styles/slick.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import Fonts from '@theme/Fonts'
import RefundTag from '@components/refund-tag'
import CartLocalStorage from '@components/cart-local-storage'
import { ReelProvider } from '@lib/context/reel-context'
import dynamic from 'next/dynamic'
import MetaPixelScript from '@components/custom-script/meta-pixel-script'
import 'aos/dist/aos.css'

const AppScripts = dynamic(() => import('@components/app-scripts'), {
  ssr: false
})

function App({
  Component,
  pageProps
}: AppPropsWithLayout<{ dehydratedState?: unknown }>) {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <MetaPixelScript />
      <MedusaProvider
        baseUrl={MEDUSA_BACKEND_URL}
        queryClientProviderProps={{
          client: queryClient
        }}
      >
        <Hydrate state={pageProps.dehydratedState}>
          <ReelProvider>
            <CartDropdownProvider>
              <MobileMenuProvider>
                <CartProvider>
                  <StoreProvider>
                    <AccountProvider>
                      <ChakraProvider theme={theme}>
                        <CartLocalStorage />
                        <AppScripts />
                        <Fonts />
                        <RefundTag />
                        {getLayout(<Component {...pageProps} />)}
                      </ChakraProvider>
                    </AccountProvider>
                  </StoreProvider>
                </CartProvider>
                <ToastContainer />
              </MobileMenuProvider>
            </CartDropdownProvider>
          </ReelProvider>
        </Hydrate>
      </MedusaProvider>
    </>
  )
}

export default App
