import React, { createContext, useContext, useEffect, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'
import { Reel, ReelStatus } from '@common/interface/reel'
import { LOCAL_REELS, LOCAL_REELS_LIKE } from '@constants/local-storage'
import { listReels, updateReel } from '@lib/util/reel-handle'
import { LineItem } from '@medusajs/medusa'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useLocalStorage } from 'usehooks-ts'

interface ReelContextValue {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  reels: Reel[]
  slideIndex: number
  setSlideIndex: (index: number) => void
  hoverIndex: number | null
  setHoverIndex: (index: number) => void
  addView: (id: string, view: number) => void
  addShare: (reel: Reel, callback: () => void) => void
  addToCart: (product_id: string) => void
  addReelLocal: (reel_id: string, product_id: string) => void
  addOrderPaid: (items: LineItem[]) => void
  addLikes: (reel_id: string, callback: () => void) => void
  likes: string[]
  muted: boolean
  setMuted: (input: boolean) => void
  auto: boolean
  setAuto: (input: boolean) => void
  setFilter: (filter: string) => void
}

interface ReelProviderProps {
  children: React.ReactNode
}

interface ReelLocal {
  reel_id: string
  product_id: string
}

export const ReelContext = createContext<ReelContextValue | null>(null)

export const ReelProvider: React.FC<ReelProviderProps> = ({ children }) => {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()

  const [reels, setReels] = useState<Reel[]>([])
  const [slideIndex, setSlideIndex] = useState<number>(0)
  const [hoverIndex, setHoverIndex] = useState<number | null>(null)
  const [muted, setMuted] = useState(true)
  const [auto, setAuto] = useState(false)
  const [filter, setFilter] = useState<string>('')

  const { isLoading, data } = useQuery(
    ['list-reels'],
    async () => listReels(),
    { staleTime: 1000 }
  )
  const { mutate: update } = useMutation(updateReel)

  const [reelLocal, setReelLocal] = useLocalStorage<ReelLocal[] | null>(
    LOCAL_REELS,
    null
  )
  const [reelLikeLocal, setReelLikeLocal] = useLocalStorage<string[] | null>(
    LOCAL_REELS_LIKE,
    null
  )

  useEffect(() => {
    if (data?.data != null && data.data.count > 0) {
      let filterReels = (data.data.reels as Reel[]).filter(
        (r) =>
          r.status === ReelStatus.PUBLISHED &&
          r.start_date != null &&
          new Date(r.start_date).getTime() <= new Date().getTime()
      )
      if (filter) {
        filterReels = filterReels.filter((r) =>
          r.title.toLowerCase().includes(filter.toLowerCase())
        )
      }
      setReels([...filterReels])
    }
  }, [data, filter])

  const addView = (id: string, views: number) => {
    update(
      { id, views },
      {
        onError(err) {
          console.log(err)
        }
      }
    )
  }

  const addShare = (reel: Reel, callback: () => void) => {
    update(
      { id: reel.id, shares: reel.shares + 1 },
      {
        onSuccess() {
          callback()
        },
        onError(err) {
          console.log(err)
        }
      }
    )
  }

  const addReelLocal = (reel_id: string, product_id: string) => {
    if (reelLocal == null) {
      setReelLocal([{ reel_id, product_id }])
    } else {
      const index = reelLocal.findIndex((rl) => rl.reel_id === reel_id)
      if (index !== -1) {
        reelLocal[index].product_id = product_id
        setReelLocal([...reelLocal])
      } else {
        reelLocal.push({ reel_id, product_id })
        setReelLocal([...reelLocal])
      }
    }
  }

  const addToCart = (product_id: string) => {
    if (reelLocal != null) {
      const reelL = reelLocal.find((rl) => rl.product_id === product_id)

      const reel = reels.find((r) => r.id === reelL?.reel_id)

      if (reelL != null && reel != null) {
        update(
          { id: reel.id, add_to_cart: reel.add_to_cart + 1 },
          {
            onError(err) {
              console.log(err)
            }
          }
        )
      }
    }
  }

  const addOrderPaid = (items: LineItem[]) => {
    const payloads: any[] = []
    const reelsDel: string[] = []
    if (reelLocal != null) {
      for (let i = 0; i < reelLocal.length; i++) {
        const reelL = reelLocal[i]
        const item = items.find(
          (item) => item?.variant?.product_id === reelL.product_id
        )

        if (item != null) {
          const reel = reels.find((r) => r.id === reelL.reel_id)
          if (reel != null) {
            reelsDel.push(reel.id)
            payloads.push({
              id: reel.id,
              order: reel.order + 1,
              sales: reel.sales + item.unit_price,
              unit_sold: reel.unit_sold + item.quantity
            })
          }
        }
      }

      for (let i = 0; i < payloads.length; i++) {
        update(payloads[i], {
          onError(err) {
            console.log(err)
          }
        })
      }

      const reelLocalFilter = reelLocal.filter(
        (rl) => !reelsDel.includes(rl.reel_id)
      )
      setReelLocal([...reelLocalFilter])
    }
  }

  const addLikes = (reel_id: string, callback: () => void) => {
    if (reelLikeLocal != null) {
      const like = reelLikeLocal.find((rll) => rll === reel_id)
      const reel = reels.find((r) => r.id === reel_id)
      if (like == null && reel != null) {
        setReelLikeLocal([...reelLikeLocal, reel_id])
        update(
          { id: reel_id, likes: reel.likes + 1 },
          {
            onSuccess() {
              callback()
            },
            onError(err) {
              console.log(err)
              setReelLikeLocal([
                ...reelLikeLocal.filter((rll) => rll !== reel_id)
              ])
            }
          }
        )
      }
    } else {
      const reel = reels.find((r) => r.id === reel_id)
      if (reel != null) {
        setReelLikeLocal([reel_id])
        update(
          { id: reel_id, likes: reel.likes + 1 },
          {
            onSuccess() {
              callback()
            },
            onError(err) {
              console.log(err)
              setReelLikeLocal([])
            }
          }
        )
      }
    }
  }

  return (
    <ReelContext.Provider
      value={{
        isOpen,
        reels,
        slideIndex,
        onClose,
        onOpen,
        onToggle,
        setSlideIndex,
        addView,
        addReelLocal,
        addToCart,
        addOrderPaid,
        addLikes,
        addShare,
        likes: reelLikeLocal ?? [],
        hoverIndex,
        setHoverIndex,
        muted,
        setMuted,
        auto,
        setAuto,
        setFilter
      }}
    >
      {children}
    </ReelContext.Provider>
  )
}

export const useReel = () => {
  const context = useContext(ReelContext)

  if (context === null) {
    throw new Error('useReel must be used within a ReelProvider')
  }
  return context
}

export default ReelContext
