const ANCHOR = {
  EXCHANGES_RETURNS: 'exchange-and-return',
  REFUND_POLICY: 'refund-policy',
  DELIVERY_POLICY: 'delivery-policy',
  WARRANTY: 'warranty',
  PRIVACY_POLICY: 'privacy-policy',
  REWARD_POINTS_T_C: 'terms-and-conditions'
}

const HOME = {
  ROOT: '/'
}

const ACCOUNT = {
  ROOT: '/account'
}

const AUTH = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  RESET_PASSWORD: '/auth/reset-password',
  VERIFY_PHONE: '/auth/verify-phone'
}

const CART = {
  ROOT: '/cart'
}

const SHOP = {
  ROOT: '/shop'
}

const PRODUCT = {
  ROOT: '/products'
}

const BRAND = {
  ROOT: '/brands'
}

const READ = {
  ROOT: '/read'
}

const REWARD_POINTS = {
  ROOT: '/reward-points',
  T_C: `/reward-points#${ANCHOR.REWARD_POINTS_T_C}`
}

const DEALS = {
  ROOT: '/deals'
}

const FLASH_SALE = {
  ROOT: '/flash-sale'
}

const OUR_STORY = '/our-story'
const TERMS_CONDITIONS = '/terms-and-conditions'
const CONTACT_US = '/contact-us-page'

const CUSTOMER_SERVICE = {
  TERMS_CONDITIONS,
  EXCHANGES_RETURNS: `${TERMS_CONDITIONS}#${ANCHOR.EXCHANGES_RETURNS}`,
  REFUND_POLICY: `${TERMS_CONDITIONS}#${ANCHOR.REFUND_POLICY}`,
  DELIVERY_POLICY: `${TERMS_CONDITIONS}#${ANCHOR.DELIVERY_POLICY}`,
  WARRANTY: `${TERMS_CONDITIONS}#${ANCHOR.WARRANTY}`
}

const INFORMATION = {
  FAQ: '/faq',
  CAREER: '/career',
  PARTNERS: '/partners',
  OUR_STORY,
  PRIVACY_POLICY: `${TERMS_CONDITIONS}#${ANCHOR.PRIVACY_POLICY}`
}

const RouterUrl = {
  HOME,
  OUR_STORY,
  SHOP,
  PRODUCT,
  BRAND,
  READ,
  ACCOUNT,
  AUTH,
  CART,
  CUSTOMER_SERVICE,
  INFORMATION,
  REWARD_POINTS,
  DEALS,
  FLASH_SALE,
  CONTACT_US,
  ANCHOR,
  500: '/500'
}

export default RouterUrl
