import { Global } from '@emotion/react'

// All fonts:
// DMSans
// ITC Avant Garde Gothic Pro
// Myriad Pro
const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/inter/Inter-Regular.ttf') format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/inter/Inter-Medium.ttf') format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        src: url('/fonts/inter/Inter-SemiBold.ttf') format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/inter/Inter-Bold.ttf') format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        src: url('/fonts/inter/Inter-ExtraBold.ttf') format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'DM Sans Regular';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/dmsans/DMSans-Regular.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'DM Sans';
        font-style: italic;
        font-weight: 400;
        src: url('/fonts/dmsans/DMSans-Italic.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'DM Sans';
        font-style: normal;
        src: url('/fonts/dmsans/DMSans-Medium.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'DM Sans';
        font-style: italic;
        font-weight: 500;
        src: url('/fonts/dmsans/DMSans-MediumItalic.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'DM Sans Bold';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/dmsans/DMSans-Bold.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'DM Sans';
        font-style: italic;
        font-weight: 700;
        src: url('/fonts/dmsans/DMSans-BoldItalic.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'ITC';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/itcpro/ITC-Avant-Garde-Gothic-Pro-Light.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'ITC';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/itcpro/ITC-Avant-Garde-Gothic-Pro-Book.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'ITC';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/itcpro/ITC-Avant-Garde-Gothic-Pro-Medium.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'ITC';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/itcpro/ITC-Avant-Garde-Gothic-Pro-Demi.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'ITC';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/itcpro/ITC-Avant-Garde-Gothic-Pro-Bold.otf') format('opentype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        font-display: swap;
      }

      @font-face {
        font-family: 'Myriad Pro';
        src: url('/fonts/myriad/myriad-pro-regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
      `}
  />
)

export default Fonts
