export const handleError = (error: Error) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error)
  }

  // TODO: user facing error message
}

export const handleAuthError = (
  error: any,
  callback: (mess: string) => void
) => {
  if (error.response?.data) {
    callback(error.response.data.message || (error.response.data as string))
  } else {
    callback('An error occured. Please try again.')
  }
}
