import useGA4DataLayer from '@common/hooks/use-ga4-data-layer'
import { CARTS_LOCAL } from '@constants/local-storage'
import socket from '@lib/socket'
import React, { useEffect } from 'react'

const RefundTag = () => {
  const { refundTag } = useGA4DataLayer()

  useEffect(() => {
    // Clear old event listener
    socket.off('refund_order')
    socket.off('connect_error')

    socket.on('refund_order', (value: any) => {
      if (value?.order != null && typeof window !== 'undefined') {
        const carts = localStorage.getItem(CARTS_LOCAL)
        if (carts) {
          const carts_local = JSON.parse(carts)
          const cart_local = carts_local.find(
            (cl: any) => cl === value?.order?.cart_id
          )
          if (cart_local != null) {
            const dl = (window as any).dataLayer as any
            refundTag(value.order as any, dl)
          }
        }
      }
    })

    socket.on('connect_error', (error: any) => {
      console.error('Socket connection error:', error)
    })
  }, [refundTag])

  return <></>
}

export default RefundTag
