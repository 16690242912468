import { CARTS_LOCAL } from '@constants/local-storage'
import { useCart } from 'medusa-react'
import React, { useEffect } from 'react'

const CartLocalStorage = () => {
  const { cart } = useCart()

  useEffect(() => {
    if (cart != null) {
      const carts = localStorage.getItem(CARTS_LOCAL)
      if (carts != null) {
        const carts_local = JSON.parse(carts)
        const cart_local = carts_local.find((cl: any) => cl === cart.id)
        if (!cart_local) {
          carts_local.push(cart.id)
          localStorage.setItem(
            CARTS_LOCAL,
            JSON.stringify(Array.from(new Set(carts_local)))
          )
        }
      } else {
        localStorage.setItem(CARTS_LOCAL, JSON.stringify([cart.id]))
      }
    }
  }, [cart])

  return <></>
}

export default CartLocalStorage
