import { BACKEND_URL_STORE } from '@constants/server.constant'
import axios from 'axios'

export const listReels = async () => {
  return await axios.get(`${BACKEND_URL_STORE}/reels`)
}

export const updateReel = async (payload: any) => {
  const { id } = payload
  delete payload.id
  return await axios.put(`${BACKEND_URL_STORE}/reels/${id as string}`, payload)
}
