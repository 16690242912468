import { extendTheme } from '@chakra-ui/react'
import { tabsTheme } from './tabs-theme'
import textStyles from './text-styles.theme'
import colors from './colors.theme'
import fonts from './fonts.theme'
import styles from './styles.theme'
import Heading from './heading.theme'
import Text from './text.theme'
import Button from './button.theme'
import Container from './container.theme'

export const theme = extendTheme({
  colors,
  textStyles,
  styles,
  fonts,
  components: {
    Tabs: tabsTheme,
    Heading,
    Text,
    Button,
    Container
  }
})
