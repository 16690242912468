import { medusaClient } from '@lib/config'
import { handleError } from '@lib/util/handle-error'
import { Region } from '@medusajs/medusa'
import {
  useCart,
  useCreateLineItem,
  useDeleteLineItem,
  useUpdateLineItem
} from 'medusa-react'
import React, { useEffect, useState } from 'react'
import { useCartDropdown } from './cart-dropdown-context'
import useGA4DataLayer from '@common/hooks/use-ga4-data-layer'
import _ from 'lodash'
import { listSaleChannelsByRegionId } from '@lib/util/region-sale-channel-handler'

interface VariantInfoProps {
  variantId: string
  quantity: number
}

interface LineInfoProps {
  lineId: string
  quantity: number
}

interface StoreContext {
  countryCode: string | undefined
  setRegion: (
    regionId: string,
    countryCode: string,
    saleChannels?: string[]
  ) => void
  getRegion: () => { regionId: string; countryCode: string } | null
  addItem: (item: VariantInfoProps) => void
  updateItem: (item: LineInfoProps) => void
  deleteItem: (lineId: string) => void
  resetCart: () => void
  loading: boolean
  success: boolean
  setSuccess: (success: boolean) => void
  saleChannels: string[]
}

const StoreContext = React.createContext<StoreContext | null>(null)

export const useStore = () => {
  const context = React.useContext(StoreContext)
  if (context === null) {
    throw new Error('useStore must be used within a StoreProvider')
  }
  return context
}

interface StoreProps {
  children: React.ReactNode
}

const IS_SERVER = typeof window === 'undefined'
const CART_KEY = 'medusa_cart_id'
const REGION_KEY = 'medusa_region'

export const StoreProvider = ({ children }: StoreProps) => {
  const { cart, setCart, createCart, updateCart } = useCart()
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined)
  const { timedOpen } = useCartDropdown()
  const { addToCartTag, removeFromCartTag } = useGA4DataLayer()

  const cardId: string = cart ? cart.id : ''

  const addLineItem = useCreateLineItem(cardId)
  const removeLineItem = useDeleteLineItem(cardId)
  const adjustLineItem = useUpdateLineItem(cardId)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [saleChannels, setSaleChannels] = useState<string[]>([])

  const storeRegion = (
    regionId: string,
    countryCode: string,
    saleChannels: string[]
  ) => {
    if (!IS_SERVER) {
      localStorage.setItem(
        REGION_KEY,
        JSON.stringify({ regionId, countryCode, saleChannels })
      )

      setCountryCode(countryCode)
      setSaleChannels(saleChannels)
    }
  }

  useEffect(() => {
    if (!IS_SERVER) {
      const storedRegion = localStorage.getItem(REGION_KEY)
      if (storedRegion) {
        const { countryCode, saleChannels } = JSON.parse(storedRegion)
        setCountryCode(countryCode)
        setSaleChannels(saleChannels)
      }
    }
  }, [])

  const getRegion = (): { regionId: string; countryCode: string } | null => {
    if (!IS_SERVER) {
      const region = localStorage.getItem(REGION_KEY)
      if (region) {
        return JSON.parse(region) as {
          regionId: string
          countryCode: string
          saleChannels: string[]
        }
      }
    }
    return null
  }

  const setRegion = async (
    regionId: string,
    countryCode: string,
    saleChannels?: string[]
  ) => {
    await updateCart.mutateAsync(
      {
        region_id: regionId
      },
      {
        onSuccess: ({ cart }) => {
          setCart(cart)
          storeCart(cart.id)
          storeRegion(regionId, countryCode, saleChannels || [])
        },
        onError: (error) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error)
          }
        }
      }
    )
  }

  const ensureRegion = async (region: Region, countryCode?: string | null) => {
    if (!IS_SERVER) {
      const { regionId, countryCode: defaultCountryCode } = getRegion() || {
        regionId: region.id,
        countryCode: region.countries[0].iso_2,
        saleChannels: []
      }

      const finalCountryCode = countryCode || defaultCountryCode

      const salesChannels = await listSaleChannelsByRegionId(region.id)

      const salesChannelIds = salesChannels.map((sc) => sc.id)

      if (regionId !== region.id) {
        setRegion(region.id, finalCountryCode, salesChannelIds)
      }

      storeRegion(region.id, finalCountryCode, salesChannelIds)
      setCountryCode(finalCountryCode)
    }
  }

  const storeCart = (id: string) => {
    if (!IS_SERVER) {
      localStorage.setItem(CART_KEY, id)
    }
  }

  const getCart = () => {
    if (!IS_SERVER) {
      return localStorage.getItem(CART_KEY)
    }
    return null
  }

  const deleteCart = () => {
    if (!IS_SERVER) {
      localStorage.removeItem(CART_KEY)
    }
  }

  const deleteRegion = () => {
    if (!IS_SERVER) {
      localStorage.removeItem(REGION_KEY)
    }
  }

  const createNewCart = async (regionId?: string) => {
    await createCart.mutateAsync(
      { region_id: regionId },
      {
        onSuccess: ({ cart }) => {
          setCart(cart)
          storeCart(cart.id)
          ensureRegion(cart.region, cart.shipping_address?.country_code)
        },
        onError: (error) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error)
          }
        }
      }
    )
  }

  const resetCart = async () => {
    deleteCart()

    const savedRegion = getRegion()

    createCart.mutate(
      {
        region_id: savedRegion?.regionId
      },
      {
        onSuccess: async ({ cart }) => {
          setCart(_.cloneDeep(cart))
          storeCart(cart.id)
          ensureRegion(cart.region, cart.shipping_address?.country_code)
        },
        onError: (error) => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error)
          }
        }
      }
    )
  }

  const ensureCart = async () => {
    const cartId = getCart()
    const region = getRegion()

    if (cartId) {
      const cartRes = await medusaClient.carts
        .retrieve(cartId)
        .then(({ cart }) => {
          return cart
        })
        .catch(async (_) => {
          return null
        })

      if (!cartRes || cartRes.completed_at) {
        deleteCart()
        deleteRegion()
        await createNewCart()
        return
      }

      setCart(cartRes)
      ensureRegion(cartRes.region)
    } else {
      await createNewCart(region?.regionId)
    }
  }

  useEffect(() => {
    if (!IS_SERVER && !cart?.id) {
      ensureCart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addItem = async ({
    variantId,
    quantity
  }: {
    variantId: string
    quantity: number
  }) => {
    setLoading(true)
    setSuccess(false)

    await new Promise((resolve, reject) => {
      addLineItem.mutate(
        {
          variant_id: variantId,
          quantity: quantity
        },
        {
          onSuccess: ({ cart }) => {
            const item = cart.items.find((i) => i.variant_id === variantId)

            if (item != null) {
              addToCartTag(cart.region.currency_code, (item.total ?? 0) / 100, [
                item
              ])
            }

            setCart(cart)
            storeCart(cart.id)
            timedOpen()
            setLoading(false)
            setSuccess(true)

            resolve(true)
          },
          onError: (error) => {
            handleError(error)
            setLoading(false)

            reject(error)
          }
        }
      )
    })
  }

  const deleteItem = (lineId: string) => {
    const item = cart?.items.find((i) => i.id === lineId)

    removeLineItem.mutate(
      {
        lineId
      },
      {
        onSuccess: ({ cart }) => {
          if (item) {
            removeFromCartTag(
              cart.region?.currency_code ?? 'sgd',
              (item?.total ?? 0) / 100,
              [item]
            )
          }
          setCart(cart)
          storeCart(cart.id)
        },
        onError: (error) => {
          handleError(error)
        }
      }
    )
  }

  const updateItem = ({
    lineId,
    quantity
  }: {
    lineId: string
    quantity: number
  }) => {
    adjustLineItem.mutate(
      {
        lineId,
        quantity
      },
      {
        onSuccess: ({ cart }) => {
          setCart(cart)
          storeCart(cart.id)
        },
        onError: (error) => {
          handleError(error)
        }
      }
    )
  }

  return (
    <StoreContext.Provider
      value={{
        countryCode,
        setRegion,
        addItem,
        deleteItem,
        updateItem,
        resetCart,
        loading,
        success,
        setSuccess,
        getRegion,
        saleChannels
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
